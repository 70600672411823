const useABTestOldVsNewPortal = () => {
  const portalsToBeTested = [
    // 'http://www.allgaeu-travel.com',
    // 'http://www.amrum-travel.de',
    // 'http://www.boltenhagen-travel.de',
    // 'http://www.buesum-travel.com',
    // 'http://www.chiemsee-travel.de',
    // 'http://www.cuxhaven-lodge.de',
    // 'http://www.fehmarn-travel.de',
    // 'http://www.foehr-travel.de',
    // 'http://www.hamburg-lodge.com',
    // 'http://www.hamburg-lodge.de',
    // 'http://www.kiel-travel.de',
    // 'http://www.luebeck-travel.de',
    // 'http://www.lueneburg-travel.de',
    // 'http://www.polen-travel.com',
    // 'http://www.ruegen-travel.de',
    // 'http://www.schwarzwald-travel.de',
    // 'http://www.sicily-lodge.com',
    // 'http://www.sizilien-travel.de',
    // 'http://www.stpeterording-travel.de',
    // 'http://www.timmendorferstrand-travel.de'
  ]

  const isPortalInPortalsToBeTestedList = (host: string): boolean => {
    if (portalsToBeTested.includes(host)) {
      return true
    }
    return false
  }

  return {
    isPortalInPortalsToBeTestedList
  }
}

// export { useABTestOldVsNewPortal }
export default useABTestOldVsNewPortal
