<template>
  <div class="container 2xl:max-w-screen-xl px-6 mx-auto mt-5">
    <div class="rounded-lg border border-neutral-200 py-8 px-5 md:px-10 text-left">
      <div class="grid lg:grid-cols-2 gap-y-4 gap-x-16">
        <div class="lg:col-span-2">
          <h3 class="text-2xl sm:text-4xl font-bold">
            <UiLanguagePlaceholder domain="LayoutNewsletter" name="holiday_offers" />
          </h3>
        </div>
        <div class="lg:col-span-1">
          <div class="text-base">
            <UiLanguagePlaceholder domain="LayoutNewsletter" name="register_newsletter" />
          </div>
        </div>
        <div class="lg:col-span-1 mt-3 sm:mt-0">
          <SharedNewsletterForm :layout-break="false" />
          <div class="text-sm text-left mt-2 leading-5">
            <UiLanguagePlaceholder domain="LayoutNewsletter" name="privacy_policy" :variables="{ url: '/datenschutz' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
