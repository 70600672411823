<template>
  <UiIcon v-show="itemsCount === 0" src="/svg/icons/heart-stroke.svg" width="25" height="25" class="fill-neutral-800 float-left" />
  <UiIcon v-show="itemsCount > 0" src="/svg/icons/heart-fill.svg" width="25" height="25" class="fill-cta-500 float-left" />
  <p class="float-right ml-2">
    {{ itemsCount }}
  </p>
</template>
<script setup lang="ts">
import { useWishListStore } from '~/stores/wishList'

const itemsCount = computed({
  get () {
    if (import.meta.client) {
      const wishListStore = useWishListStore()
      return wishListStore.itemsCount
    }
    return 0
  }
  // set (val) { wishListStore.itemsCount = val }
})
</script>
