<template>
  <div class="min-h-screen">
    <!-- AB Test - Old vs New portal -->
    <input
      id="gtm_variable_ab_test_alt_vs_neu_portal"
      name="gtm_variable_ab_test_alt_vs_neu_portal"
      type="hidden"
      :value="isPortalInPortalsToBeTestedList ? 'neues_portal' : 'neues_portal_without_ab_testing'"
    >
    <LayoutHeader />
    <slot />
    <LayoutNewsletter />
    <LayoutFooter />
    <DevTestOnly>
      <DevToolbar />
    </DevTestOnly>
  </div>
</template>
<script setup>
import useHost from '~/composables/useHost'
import useABTestOldVsNewPortal from '~/composables/useABTestOldVsNewPortal'

let portalHome = usePortals().currentPortal.value?.portal?.home ?? ''
if (portalHome.endsWith('/')) {
  portalHome = portalHome.slice(0, -1)
}
const isPortalInPortalsToBeTestedList = useABTestOldVsNewPortal().isPortalInPortalsToBeTestedList(portalHome)

const route = useRoute()
const host = useHost()
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: 'https://' + host + route.path
    }
  ]
}))
</script>
